import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-gtag";
import { EuiSpacer } from "@elastic/eui";
import NavButtons from "components/Outdoor_Cameras/IN-9408_WQHD/NavButtons";
import CompareOutdoor from "components/Products/Compare/CompareOutdoorFlyout";
import SmartCards from "components/Products/Flyouts/Cards/9408Cards";
import TableFeatures from "components/Outdoor_Cameras/IN-9408_WQHD/TableFeatures";
import OutdoorData from "components/Products/Flyouts/Graphs/OutdoorGraphsFlyout";
import ImageQGallery from "components/Outdoor_Cameras/IN-9408_WQHD/ImageQGallery";
import ImageQCards from "components/Outdoor_Cameras/IN-9408_WQHD/ImageQCards";
import RadarGraphCard from "components/Products/RadarGraphs/in9408-radargraph-grid";
import BreadCrumbs from "components/Layout/BreadCrumbs";
import SEOHelmet from "components/Layout/SEOHelmet";
export const _frontmatter = {
  "title": "IN-9408 WQHD",
  "path": "/Outdoor_Cameras/IN-9408_WQHD/",
  "dateChanged": "2022-10-08",
  "author": "Mike Polinowski",
  "excerpt": "The IN-9408 WQHD is the first INSTAR Outdoor IP Surveillance camera, utilizing our new Sony 1440p / WQHD STARVIS sensor.",
  "image": "../P_SearchThumb_IN-9408WQHD.png",
  "social": "/images/Search/P_SearchThumb_IN-9408WQHD.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-9008HD_white.webp",
  "chapter": "Outdoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title="IN-9408 WQHD" dateChanged="2017-12-12" author="Mike Polinowski" tag="INSTAR IP Camera" description="The IN-9408 WQHD is the first INSTAR Outdoor IP Surveillance camera, utilizing our new Sony 1440p / WQHD STARVIS sensor." image="/images/Search/P_SearchThumb_IN-9408HD.png" twitter="/images/Search/P_SearchThumb_IN-9408HD.webp" location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE="/de/Aussenkameras/IN-9408_WQHD/" locationFR="/fr/Outdoor_Cameras/IN-9408_WQHD/" crumbLabel="IN-9408" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "in-9408-wqhd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#in-9408-wqhd",
        "aria-label": "in 9408 wqhd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-9408 WQHD`}</h1>
    <CompareOutdoor mdxType="CompareOutdoor" />
    <h2 {...{
      "id": "product-overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#product-overview",
        "aria-label": "product overview permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Product Overview`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0382dee1d73dd1bafc84935585ca588b/573d3/IN-9008_Overview_width1650.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABiElEQVQoz3WSu47UMBSG53koaJYeXmDfYCUKREFNgYS23YqSjhZRUE9PhxZpy1n2kmFmh0HMJUQkk9iJ49j+kJ1NNguMpSP/9vH5z+X3CMA5x9+ru/P7Phv6OzzqDsYYtNbBrLXsSzRMOPR3uCes6xopZbCmafZW55MNifxZD973hN7hiXyFxjT9na+8s2E3pZIBl2VFHMd9V6M20HD65YyXr094dXzC+fnXPlApdZvEkOc5F5eXiKJglSTkRUGWZXxfLomm0/AuEDa65s3bdzw4eMzDR094/+FjW6Fz7HY71psNaZqyWCwYj8esVyumP34SpxmllCRJwnq9aQmtbdv49PmUw6PnPH32gslk0s9ICMF8NmMaRRRSYgBdVaRKU/5HsztRnOMsmvPt5uaeaqqqiGZzrq6v+Z1m/MoLSlGwzQVxwILtdhs68GMZhTjnqB0si4pElHcKt4Cd0ig/R2uRxoGz5MYhAnb9d7uncmMtaVUjtfnnn9XGBnI/U9W0fqkbqls8bPkPHQz/FOYIcV8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0382dee1d73dd1bafc84935585ca588b/e4706/IN-9008_Overview_width1650.avif 230w", "/en/static/0382dee1d73dd1bafc84935585ca588b/d1af7/IN-9008_Overview_width1650.avif 460w", "/en/static/0382dee1d73dd1bafc84935585ca588b/7f308/IN-9008_Overview_width1650.avif 920w", "/en/static/0382dee1d73dd1bafc84935585ca588b/e1c99/IN-9008_Overview_width1650.avif 1380w", "/en/static/0382dee1d73dd1bafc84935585ca588b/e02f7/IN-9008_Overview_width1650.avif 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0382dee1d73dd1bafc84935585ca588b/a0b58/IN-9008_Overview_width1650.webp 230w", "/en/static/0382dee1d73dd1bafc84935585ca588b/bc10c/IN-9008_Overview_width1650.webp 460w", "/en/static/0382dee1d73dd1bafc84935585ca588b/966d8/IN-9008_Overview_width1650.webp 920w", "/en/static/0382dee1d73dd1bafc84935585ca588b/445df/IN-9008_Overview_width1650.webp 1380w", "/en/static/0382dee1d73dd1bafc84935585ca588b/44758/IN-9008_Overview_width1650.webp 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0382dee1d73dd1bafc84935585ca588b/81c8e/IN-9008_Overview_width1650.png 230w", "/en/static/0382dee1d73dd1bafc84935585ca588b/08a84/IN-9008_Overview_width1650.png 460w", "/en/static/0382dee1d73dd1bafc84935585ca588b/c0255/IN-9008_Overview_width1650.png 920w", "/en/static/0382dee1d73dd1bafc84935585ca588b/b1001/IN-9008_Overview_width1650.png 1380w", "/en/static/0382dee1d73dd1bafc84935585ca588b/573d3/IN-9008_Overview_width1650.png 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0382dee1d73dd1bafc84935585ca588b/c0255/IN-9008_Overview_width1650.png",
              "alt": "INSTAR IN-9408 WQHD",
              "title": "INSTAR IN-9408 WQHD",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <TableFeatures mdxType="TableFeatures" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The IN-9408 WQHD is weather proof and wireless outdoor camera. Built-in, high-powered infrared diodes allow you to see deep into the dark. The integrated IR cut filter on the other hand allows for the capturing of native colours during the day, as seen by the human eye. The efficient h.265 compression codec allows recording of alarm videos on an internal `}<Link to="/Motion_Detection/SD_Card_Access/" mdxType="Link">{`SD Memory Card`}</Link>{` with comparably small file size but best video quality.`}</p>
    <p>{`The IN-9408 WQHD surveillance camera is ready for implementation into existing security system. But also as a stand-alone device it offers you a number of alarm features like `}<Link to="/Motion_Detection/Alarm_Notification/" mdxType="Link">{`Email & Push Notification`}</Link>{`, two-way communication and an alarm siren. The WPS function allows you to connect the camera to your WiFi network with a press of a button. Accessing your camera from the internet is even easier. The Point-2-Point connection can be established by scanning a QR code with our smartphone app. The free `}<strong parentName="p">{`InstarVision`}</strong>{` mobile app is available for `}<a parentName="p" {...{
        "href": "/en/Software/Android/InstarVision/"
      }}>{`Android`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/iOS/InstarVision/"
      }}>{`iOS`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Metro/"
      }}>{`Windows Metro`}</a>{` and `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Windows_Phone/"
      }}>{`Windows Phone`}</a>{`.`}</p>
    <OutdoorData mdxType="OutdoorData" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The sensitivity of the integrated Panasonic `}<strong parentName="p">{`Pyroelectric Sensor`}</strong>{` has been significantly improved thanks to a unique slit design of the pyroelectric elements. The separated sensing areas prevent thermal crosstalk between the single sensing elements. This makes a reliable detection possible even if the temperature difference between the background (e.g. floor /wall) and the target object (human body, car engine, etc) is small (around 4°C).`}</p>
    <h3 {...{
      "id": "hardware",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hardware",
        "aria-label": "hardware permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hardware`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`STARVIS`}</strong><code parentName="li" {...{
          "className": "language-text"
        }}>{`*`}</code>{` WQHD 1440p colour CMOS Sensor`}</li>
      <li parentName="ul">{`Nightvision with 5 Infrared LED´s @ 850nm (barely visible for the human eye) for distances up to 12 - 20m`}</li>
      <li parentName="ul">{`Very compact high quality IP66 weatherproof aluminium housing`}</li>
      <li parentName="ul">{`Integrated Panasonic Pyroelectric Sensor - PIR Range up to 12m`}</li>
    </ul>
    <h3 {...{
      "id": "network",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#network",
        "aria-label": "network permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network`}</h3>
    <ul>
      <li parentName="ul">{`RJ-45 Ethernet port for an easy integration into your 100Mbit home network`}</li>
      <li parentName="ul">{`Compatible with `}<Link to="/Quick_Installation/Set_Up_A_Wireless_Connection/" mdxType="Link">{`WiFi`}</Link>{` 5GHz / 2.4GHz, Wi‑Fi 5 (802.11ac) for 433 Mbit/s networks with WPA2/WPA3 security`}</li>
      <li parentName="ul">{`DHCP and UPnP support for an automatic network integration`}</li>
      <li parentName="ul">{`Integrated personal, free `}<Link to="/Internet_Access/The_DDNS_Service/" mdxType="Link">{`DDNS ("Internet") Address`}</Link>{` to access your camera through the Internet`}</li>
    </ul>
    <h3 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h3>
    <ul>
      <li parentName="ul">{`Free `}<Link to="/Software/Android/" mdxType="Link">{`Android`}</Link>{`, `}<Link to="/Software/iOS/" mdxType="Link">{`iPhone`}</Link>{` and `}<Link to="/Software/Windows/" mdxType="Link">{`Windows Phone`}</Link>{` App`}</li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/1440p_Series/" mdxType="Link">Web user interface</Link> allows an easy configuration and camera control through your default web browser (Chrome, Safari, Firefox, Internet Explorer)
      </li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/1440p_Series/System/User/" mdxType="Link">Multi user management</Link> and simultaneous login for 3 users
      </li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/1440p_Series/Alarm/Areas/" mdxType="Link">Software-based motion</Link> and <Link to="/Web_User_Interface/1440p_Series/Alarm/Actions/" mdxType="Link">audio</Link> detection and <Link to="/Motion_Detection/Alarm_Notification/" mdxType="Link"> {" "} email notification </Link> with attached snapshots
      </li>
      <li parentName="ul">{`Record 15s alarm videos (+ 3s pre-recording) directly to an `}<Link to="/Motion_Detection/SD_Card_Access/" mdxType="Link">{`Internal SD Card`}</Link></li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <SmartCards mdxType="SmartCards" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "image-quality",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#image-quality",
        "aria-label": "image quality permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Image Quality`}</h3>
    <ImageQGallery mdxType="ImageQGallery" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Security cameras need to be able to record, with clear images, in a variety of environments. Sony's `}<strong parentName="p">{`STARVIS`}</strong><code parentName="p" {...{
        "className": "language-text"
      }}>{`*`}</code>{` image sensors meet this need by providing high-sensitivity performance suitable for low-light and night recordings. `}<strong parentName="p">{`STARVIS`}</strong><code parentName="p" {...{
        "className": "language-text"
      }}>{`*`}</code>{` is a back-illuminated pixel technology used in CMOS image sensors for security camera applications. It features highly sensitive backlight illuminated sensor pixels, and realize high picture quality in the visible-light and near infrared light regions. The IMX335 `}<strong parentName="p">{`STARVIS`}</strong><code parentName="p" {...{
        "className": "language-text"
      }}>{`*`}</code>{` Sensor is a diagonal 2.8 inch CMOS active pixel type solid-state image sensor with a square pixel array and 5.14 M effective pixels.`}</p>
    <h3 {...{
      "id": "resolution-comparison",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#resolution-comparison",
        "aria-label": "resolution comparison permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Resolution Comparison`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <ImageQCards mdxType="ImageQCards" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Many built-in features allow you to use this camera not only for surveillance, but also for your personal projects. The `}<Link to="/Motion_Detection/Alarm_FTP_Upload/" mdxType="Link">{`FTP Support`}</Link>{`, for example, allows you to use the IN-9408 WQHD as a multi-functional outdoor webcam for `}<Link to="/Advanced_User/Website_Integration/" mdxType="Link">{`Integration on your Website`}</Link>{` - for example as a weather cam.`}</p>
    <RadarGraphCard mdxType="RadarGraphCard" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The SONY `}<strong parentName="p">{`STARVIS`}</strong><code parentName="p" {...{
        "className": "language-text"
      }}>{`*`}</code>{` IMX335 CMOS sensor features a dynamic range up to 72 dB, advanced HDR functions and digital overlap HDR further improve the image quality and contrast behavior allowing for more detailed image analysis. These features when combined with the low dark current performance further improve its performance in applications demanding a high dynamic range.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`*`}</code>{` `}<strong parentName="p">{`STARVIS`}</strong>{` is a trademark of the Sony Corporation. A STARVIS sensor is defined by a sensitivity of `}<em parentName="p">{`2000 mV`}</em>{` or more per `}<em parentName="p">{`1 μm²`}</em>{` when illuminated with a `}<em parentName="p">{`706 cd/m²`}</em>{` light source, `}<em parentName="p">{`F5.6`}</em>{` in `}<em parentName="p">{`1s`}</em>{` accumulation equivalent.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      